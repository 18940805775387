import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import TwoColCardSection from '../components/twoColCardSection';
import LinkCard from '../components/linkCard';

export default ({ data }) => {
  const interactiveRecipes = [
    {
      title: 'Sourdough Bagels',
      date: 'May 11, 2020',
      path: '/recipes/bagels',
      fluidImg: data.bagels.childImageSharp.fluid
    },
    {
      title: 'Home Oven Sourdough Pizza',
      date: 'May 8, 2020',
      path: '/recipes/homeOvenPizza',
      fluidImg: data.homeOvenPizza.childImageSharp.fluid
    },
    {
      title: 'Basic Pizza Sauce',
      date: 'July 10, 2020',
      path: '/recipes/pizzaSauce',
      fluidImg: data.pizzaSauce.childImageSharp.fluid
    },
    {
      title: 'Neapolitan Pizza',
      date: 'August 9, 2020',
      path: '/recipes/neapolitanPizza',
      fluidImg: data.neapolitanPizza.childImageSharp.fluid
    }
  ];

  return (
    <Layout>
      <Helmet>
        <title>Recipes</title>
      </Helmet>
      <h1>Recipes</h1>
      <TwoColCardSection>
        {interactiveRecipes.map(recipe => (
          <LinkCard key={recipe.path} {...recipe} />
        ))}
      </TwoColCardSection>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    homeOvenPizza: file(
      relativePath: { eq: "recipes/homeOvenPizza/main.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bagels: file(relativePath: { eq: "recipes/bagels/main.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 600, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    pizzaSauce: file(relativePath: { eq: "recipes/pizzaSauce/main.png" }) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    neapolitanPizza: file(
      relativePath: { eq: "recipes/neapolitanPizza/main.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 800, maxHeight: 600) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
